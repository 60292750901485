Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (!Element.prototype.toggleAttribute) {
	Element.prototype.toggleAttribute = function(name, force) {
		if(force !== void 0) force = !!force; 
		
		if (this.hasAttribute(name)) {
			if (force) return true;

			this.removeAttribute(name);
			return false;
		}
		if (force === false) return false;
		
		this.setAttribute(name, "");
		return true;
	};
}