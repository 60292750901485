import { AutomatitCarousel } from './automatit_carousel.m';

export const initHeroCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero'),
		images: [
			{src: "/static/images/backgrounds/home_header_1.jpg", alt: ""},
			{src: "/static/images/backgrounds/home_header_2.jpg", alt: ""},
			{src: "/static/images/backgrounds/home_header_3.jpg", alt: ""},
		],
		imagesAsBackgrounds: true,
		useChevrons: false,
	});
};

export const initSizeGuideCaro = () => {
	window.sizeGuideCaro = AutomatitCarousel({
		element: document.querySelector('#home_size_caro'),
		useChevrons: false,
		intervalTiming: false,
	});
};