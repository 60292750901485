import { AutomatitCarousel } from './automatit_carousel.m';

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_top').switchAttribute('state', 'open', 'closed');
	});
};

export const facilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		imagesAsBackgrounds: true,
		images: window.facCaroImgs
	});
};

export const initFacFilters = () => {
	const rows = document.querySelectorAll('.rates_row');
	const filters = document.querySelectorAll('#facility_filters button');
	const noUnits = document.querySelector('#facility_no_units');

	document.querySelector('#facility_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			if(clickedFilter.getAttribute('size') === 'all' && !clickedFilter.hasAttribute('active')) {
				filters.forEach(filter => filter.removeAttribute('active'));
			} else {
				document.querySelector('#facility_filters button[size="all"]').removeAttribute('active');
			}
			
			clickedFilter.toggleAttribute('active');
		}

		const activeFilters = Array.from(document.querySelectorAll('#facility_filters button[active]')).map(filter => filter.getAttribute('size'));

		let numUnits = 0;

		rows.forEach(row => {
			row.setAttribute('hide', true);

			if(activeFilters.includes(row.getAttribute('size')) || !activeFilters.length || activeFilters.includes('all')) {
				row.removeAttribute('hide');
				numUnits++;
			}
		});

		if(!numUnits) {
			noUnits.style.display = 'block';
		} else {
			noUnits.style.display = 'none';
		}
	});
};