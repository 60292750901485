export const initTipsList = () => {
	const tips = document.querySelectorAll('#tips_list li');
	document.querySelector('#tips_list').addEventListener('click', e => {
		const clickedHeading = e.target.closest('h4');

		if(clickedHeading) {
			const theItem = clickedHeading.closest('li');

			if(theItem.hasAttribute('active')) {
				theItem.removeAttribute('active');
			} else {
				tips.forEach(tip => tip.removeAttribute('active'));
				theItem.setAttribute('active', true);
			}

		}
	});

	setTimeout(() => {
		document.querySelector('#tips_list li h4').click();
	}, 500);

};