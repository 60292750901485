import './polyfills';

import { initMobileNav, initStickyNav } from './header';
import { initHeroCaro, initSizeGuideCaro } from './home';
import { initFacilityMobile, facilityCaro, initFacFilters } from './facility';
import { contactSubmit } from './contact.js';
import { initMap } from './map';
import { initReviewsCaro } from './reviewsCaro';
import { initTipsList } from './help';

initMobileNav();


if(document.querySelector('#home_hero')) {
	initStickyNav();
	initHeroCaro();
}

if(document.querySelector('#home_size_caro')) {
	initSizeGuideCaro();
}

if(document.querySelector('#toggle_fac_info')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	facilityCaro();
}

if(document.querySelector('#facility_filters')) {
	initFacFilters();
}

if(document.querySelector('#inc_map')) {
	initMap();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#tips_list')) {
	initTipsList();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'message']
	});
}
