/* global google */
import { getMapsHelper } from './util/mapsHelper';

export const initMap = async () => {
	const mapHelper = getMapsHelper();

	await mapHelper.ready();

	const theMap = mapHelper.createMap({
		element: document.querySelector('#inc_map'),
		locationElementSelector: '#inc_map',
		useRichmarker: true,
		markerReducer: el => {
			return {
				lat: el.getAttribute('lat'),
				lng: el.getAttribute('lng'),
				content: `<span><img src="/dist/images/icons/map_pin.svg" alt="" /></span>`
			};
		}
	});

	google.maps.event.addListenerOnce(theMap, 'idle', () => {
		theMap.setZoom(15);
	});
};