/* global Velocity */

export const initMobileNav = () => {	
	const mobileNav = document.querySelector('#toggle_mobile_nav');
	mobileNav.addEventListener('click', () => {
		mobileNav.switchAttribute('state', 'open', 'closed');
		document.querySelector('#nav').switchAttribute('state', 'open', 'closed');

		if(mobileNav.getAttribute('state') === 'open' && document.querySelector('#header').hasAttribute('scroll')) {
			Velocity(document.querySelector('#header'), 'scroll');
		}
	});
};

export const initStickyNav = () => {
	const header = document.querySelector('#header');
	const hero = document.querySelector('#home_hero');
	const container = document.querySelector('#container');

	window.addEventListener('scroll', () => {
		if(window.scrollY >= hero.offsetHeight) {
			header.removeAttribute('scroll');
			container.removeAttribute('no_top_padding');
		} else {
			header.setAttribute('scroll', true);
			container.setAttribute('no_top_padding', true);

		}
	});
};