import { AutomatitCarousel } from './automatit_carousel.m';

export const initReviewsCaro = () => { 
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#inc_reviews_caro'),
		useChevrons: false,
		afterSlide: (p, n) => {
			document.querySelector('#inc_reviews_caro_controls h5 span').innerHTML = +n.dataset.slide+1;
		}
	});
};